import axios from "axios";
import { SERVICE_URL } from "./Utility";

/**
 * Fetch data from given url
  @param {} url
  @param {} options
 */

const token = localStorage.getItem("token" && "token");
const csrfToken = localStorage.getItem("access");
const headers = { "auth-token": token };

const servicePost = async (path, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVICE_URL}/${path}`, payload, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const serviceimagePost = async (path, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVICE_URL}/${path}`, payload, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          // "xsrf-token": csrfToken,
          // "auth-token": token,
        },
        // credentials: "include",
        // mode: "cors",
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const serviceUpdate = async (path, payload, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${SERVICE_URL}/${path}`, payload, {
        headers: {
          headers: { "auth-token": token },
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const serviceGet = async (path) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${SERVICE_URL}/${path}`, {
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": token,
        },
        credentials: "include",
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const serviceDelete = async (path, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${SERVICE_URL}/${path}`, payload, {
        headers: { "auth-token": token },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
const servicePut = async (path, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${SERVICE_URL}/${path}`, payload, {
        headers: { "auth-token": token },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
const servicePatch = async (path, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${SERVICE_URL}/${path}`, payload, {
        headers: { "auth-token": token },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export {
  servicePost,
  serviceGet,
  serviceUpdate,
  serviceDelete,
  servicePut,
  servicePatch,
  serviceimagePost,
};
